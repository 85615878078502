import {useState} from "react"
import {EVENT_USER_INTERACT_TEST_VIEW} from "Event/EventType"
import GraphQLService from "Service/GraphQLService"
import {gql} from "@apollo/client"
import {Button, Modal} from "react-bootstrap"
import useEvent from "Hook/useEvent"

import "./TestView.scss"


const TestView = () => {
    const [test, setTest] = useState({})
    const [display, setDisplay] = useState(false)

    useEvent(EVENT_USER_INTERACT_TEST_VIEW, e => {
        let test = e.data.test
        setTest(test)
        if (test.id) {
            loadTest(test.id)
        } else {
            setDisplay(true)
        }
    }, [test])

    const loadTest = (testId) => {
        if (testId > 0) {
            GraphQLService.query(gql`
              query GetTestForView($testId: ID!) {
                  Test(id: $testId) {
                    id
                    title
                    questions {
                      id
                      text
                    }
                  }
              }
            `, {testId})
                .then(result => {
                    if (result.data.Test) {
                        setTest(result.data.Test)
                        setDisplay(true)
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        }
    }

    const onClose = () => {
        setDisplay(false)
    }

    if (!display) return null

    const list = test.questions.map(item => {
        return <li key={item.id} className="question">{item.text}</li>
    })

    // animation={false} т.к. иначе ругается реакт на "findDOMNode is deprecated in StrictMode"
    return <Modal show={display} onHide={onClose} size={"lg"} animation={false} centered={true} className="test-view">
        <Modal.Header closeButton>
            <Modal.Title>Тест</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>{test.title}</h3>
            <ol>
                {list}
            </ol>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onClose} variant="light">
                Закрыть
            </Button>
        </Modal.Footer>
    </Modal>
}

export default TestView
