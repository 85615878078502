import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import MainEvent from "../../Event/MainEvent";
import EventDispatcherService from "../../Service/EventDispatcherService";

const ButtonPlay = ({answer}) => {

    const onButtonPlay = () => {
        let event = new MainEvent('user.interact.play-answer', {answer: answer})
        EventDispatcherService.fire(event)
    }

    return <button
        type="button"
        className="btn btn-light"
        onClick={onButtonPlay}
    ><FontAwesomeIcon icon={faPlay} /> Play </button>
}

export default ButtonPlay
