import {useState} from "react";
import EventDispatcherService from "Service/EventDispatcherService";
import {EVENT_USER_INTERACT_USER_VIEW, EVENT_USER_INTERACT_VIEW_CHALLENGE} from "Event/EventType";
import GraphQLService from "Service/GraphQLService";
import {gql} from "@apollo/client";
import {Button, Modal, Card, Table} from "react-bootstrap";
import {formatDate} from "Service/Utils";
import MainEvent from "Event/MainEvent";
import useEvent from "Hook/useEvent";

const UserView = () => {

    const [user, setUser] = useState({})
    const [display, setDisplay] = useState(false)

    useEvent(EVENT_USER_INTERACT_USER_VIEW, e => {
        let user = e.data.user
        setUser(user)
        if (user.id) {
            loadUser(user.id)
        } else {
            setDisplay(true)
        }
    }, [user])

    const loadUser = (userId) => {
        if (userId > 0) {
            GraphQLService.query(gql`
              query GetUserFull($userId: ID!) {
                  User(id: $userId) {
                    id
                    firstName
                    middleName
                    lastName
                    email
                    dateAdd
                    challenges {
                      id
                      code
                      dateEnd
                      test {
                        id
                        title
                      }
                    }
                  }
              }
            `, {userId})
                .then(result => {
                    if (result.data.User) {
                        setUser(result.data.User)
                        setDisplay(true)
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        }
    }

    const onClose = () => {
        setDisplay(false)
    }

    if (!display) return null

    const handleChallengeView = challengeId => {
        const event = new MainEvent(EVENT_USER_INTERACT_VIEW_CHALLENGE, {challengeId})
        EventDispatcherService.fire(event)
    }

    let list = user.challenges.map(item => {
        const testPassed = !!item.dateEnd

        let link = document.location.origin + '/self/' + item.code
        let testTitle = item.test.title

        if (testPassed) {
            link = null
            testTitle = <a href="/" onClick={(e) => { e.preventDefault(); handleChallengeView(item.id) }}>{item.test.title}</a>
        }

        return <tr key={item.id}>
            <td>{testTitle}</td>
            <td>{link}</td>
            <td>{formatDate(item.dateEnd)}</td>
        </tr>
    })

    let emailBlock = null
    if (user.email) {
        emailBlock = <tr><td>Email:</td><td><a href="mailto:{user.email}">{user.email}</a></td></tr>
    }

    let dateAddBlock = null
    if (user.dateAdd) {
        dateAddBlock = <tr><td>Зарегистрирован:</td><td>{formatDate(user.dateAdd)}</td></tr>
    }

    // animation={false} т.к. иначе ругается реакт на "findDOMNode is deprecated in StrictMode"
    return <Modal show={display} onHide={onClose} size={"lg"} animation={false}>
        <Modal.Header closeButton>
            <Modal.Title>Соискатель</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>{user.lastName} {user.firstName} {user.middleName}</h3>

            <Table>
                <tbody>
                    {emailBlock}
                    {dateAddBlock}
                </tbody>
            </Table>

            <Card>
                <Card.Header className="bg-light">Назначенные тесты</Card.Header>
                <Card.Body>
                    <Table>
                        <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Ссылка соискателю</th>
                            <th>Пройден</th>
                        </tr>
                        </thead>
                        <tbody>
                            {list}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onClose} variant="light">
                Закрыть
            </Button>
        </Modal.Footer>
    </Modal>
}

export default UserView
