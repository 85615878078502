import Paginator from "Component/Paginator/Paginator"
import ChallengesListItem from "./ChallengesListItem";
import {PAGE_SIZE} from "Config";

const ChallengesList = ({challengesList, pageData, page, handlePageClick}) => {

    let count = pageData.totalCount
    const rows = challengesList.map(item => {
        const num = count - page * PAGE_SIZE
        count--
        return <ChallengesListItem
            challenge={item}
            num={num}
            key={item.id}
        />
    })

    let paginator = null
    if (pageData.totalCount > PAGE_SIZE) {
        paginator = <Paginator
            pageNum={page}
            onPageClick={handlePageClick}
            pageCount={Math.ceil(pageData.totalCount / PAGE_SIZE)}
        />
    }

    return <>
        <table className="table">
            <thead>
            <tr>
                <th>№</th>
                <th>Соискатель</th>
                <th>Зарегистрирован</th>
                <th>Тест</th>
                <th>Ссылка соискателю</th>
                <th>Начат</th>
                <th>Закончен</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>

        {paginator}
    </>
}

export default ChallengesList
