import SpyLog from "../../Service/SpyLog";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const GraphChars = ({data}) => {
    //console.log('data', data)
    let graphData = SpyLog.countLogChars(data)
    //console.log(graphData)

    const options = {
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: false
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                }
            },
            series: {
                animation: false
            }
        },

        xAxis: {
            plotBands: graphData.focus,
            title1: {
                text: 'sec'
            }
        },

        series: [{
            name: 'Chars',
            data: SpyLog.getList(graphData.counts)
        }]

    }

    return <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{className: "graph"}}
        />
}

export default GraphChars
