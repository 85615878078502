import AuthService from "../Service/AuthService"
import { NavLink } from "react-router-dom"

const handleLogout = (e) => {
    e.preventDefault()
    AuthService.logout()
    window.location.reload()
}

const TopMenu = () =>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <span className="navbar-brand mb-0 h1">HRTop</span>

        <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item"><NavLink to="/users" activeClassName='active' className="nav-link">Соискатели</NavLink></li>
                <li className="nav-item"><NavLink to="/tests" activeClassName='active' className="nav-link">Тесты</NavLink></li>
                <li className="nav-item"><NavLink to="/challenges" activeClassName='active' className="nav-link">Назначенные тесты</NavLink></li>
            </ul>

            <ul className="nav navbar-nav navbar-right">
                <li className="nav-item"><a className="nav-link" href="/" onClick={handleLogout}>Logout</a></li>
            </ul>
        </div>
    </nav>

export default TopMenu
