import IsLoadingError from "Component/Users/IsLoadingError";
import IsLoading from "Component/Users/IsLoading";
import {useEffect, useRef, useState} from "react";
import GraphQLService from "Service/GraphQLService";
import {gql} from "@apollo/client";
import ChallengesList from "./ChallengesList";
import {EVENT_ENTITY, EVENT_USER_INTERACT_SEARCH} from "Event/EventType";
import Search from "Component/Search/Search";
import useEvent from "Hook/useEvent";

const Challenges = () => {

    const [pageData, setPageData] = useState({
        totalCount: 0,
        list: []
    })

    const [page, setPage] = useState(0)
    const [find, setFind] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingError, setIsLoadingError] = useState(false)

    const currentPage = useRef(page)
    useEffect(() => {
        currentPage.current = page
    })

    useEffect(() => {
        loadPage(page, find)
    }, [page, find]);

    useEvent(EVENT_ENTITY, event => {
        if (event.isEntity('Challenge') && event.isOperation(['add', 'delete'])) {
            GraphQLService.reset()
                .then(() => {
                    loadPage(currentPage.current)
                })
        }
    }, [page, find])

    useEvent(EVENT_USER_INTERACT_SEARCH, event => {
        setFind(event.data.find)
        setPage(0)
    })

    const loadPage = (page, find) => {
        setIsLoading(true)
        setIsLoadingError(false)
        GraphQLService.query(gql`
              query GetChallenges($page: Int!, $find: String) {
                  Challenges(page: $page, find: $find) {
                      challenges {
                          id
                          test {
                              id
                              title
                          }
                          user {
                              id
                              firstName
                              lastName
                              dateAdd
                          }
                          code
                          dateStart
                          dateEnd
                      }
                      totalCount
                  }
              }
            `, {page: page+1, find: find})
            .then(result => {
                setIsLoading(false)
                setPageData({
                    totalCount: result.data.Challenges.totalCount,
                    list: result.data.Challenges.challenges
                })
            })
            .catch((e) => {
                setIsLoadingError(true)
                console.error(e)
            })
    }

    const handlePageClick = (data) => {
        setPage(data.selected)
    }

    let listContent

    if (isLoadingError) {
        listContent = <IsLoadingError />
    } else {
        if (isLoading) {
            listContent = <IsLoading />
        } else {
            listContent = <ChallengesList
                page={page} handlePageClick={handlePageClick} pageData={pageData}
                challengesList={pageData.list} />
        }
    }

    return <>
        <h1>Назначенные тесты</h1>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="collapse navbar-collapse">
                <Search />
            </div>
        </nav>

        {listContent}
    </>
}

export default Challenges
