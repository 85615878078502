import { useState, useEffect, useRef } from 'react'
import { gql } from '@apollo/client'
import GraphQLService from 'Service/GraphQLService'
import IsLoading from "./IsLoading"
import UsersList from "./UsersList"
import IsLoadingError from "./IsLoadingError"
import {EVENT_ENTITY, EVENT_USER_INTERACT_SEARCH} from "Event/EventType";
import ButtonAddUser from "./ButtonAddUser";
import UserEdit from "Component/UserEdit/UserEdit";
import UserView from "Component/UserView/UserView";
import Search from "Component/Search/Search";
import useEvent from "Hook/useEvent";

function Users() {
    const [pageData, setPageData] = useState({
        totalCount: 0,
        list: []
    })

    const [page, setPage] = useState(0)
    const [find, setFind] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingError, setIsLoadingError] = useState(false)

    const currentPage = useRef(page)
    useEffect(() => {
        currentPage.current = page
    })

    useEffect(() => {
        loadPage(page, find)
    }, [find, page]);

    useEvent(EVENT_ENTITY, event => {
        if (event.isEntity('User') && event.isOperation(['add', 'delete'])) {
            GraphQLService.reset()
                .then(() => {
                    loadPage(currentPage.current, find)
                })
        }
    }, [find, page])

    useEvent(EVENT_USER_INTERACT_SEARCH, event => {
        setFind(event.data.find)
        setPage(0)
    })

    const loadPage = (page, find) => {
        setIsLoading(true)
        setIsLoadingError(false)
        GraphQLService.query(gql`
              query GetUsers($page: Int!, $find: String) {
                  Users(page: $page, find: $find) {
                      users {
                        id
                        firstName
                        lastName
                        email
                        dateAdd
                      }
                      totalCount
                  }
              }
            `, {page: page+1, find: find})
            .then(result => {
                setIsLoading(false)
                setPageData({
                    totalCount: result.data.Users.totalCount,
                    list: result.data.Users.users
                })
            })
            .catch((e) => {
                setIsLoadingError(true)
                console.error(e)
            })
    }

    const handlePageClick = (data) => {
        setPage(data.selected)
    }

    const onUpdate = () => {
        loadPage(page, find)
    }

    let listContent

    if (isLoadingError) {
        listContent = <IsLoadingError />
    } else {
        if (isLoading) {
            listContent = <IsLoading />
        } else {
            listContent = <UsersList page={page} handlePageClick={handlePageClick} pageData={pageData}
                usersList={pageData.list} onUpdate={onUpdate} />
        }
    }

    return <>
        <h1>Соискатели</h1>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="collapse navbar-collapse">
                <Search />

                <ButtonAddUser />
            </div>
        </nav>

        <UserEdit onUpdate={onUpdate} />

        <UserView />

        {listContent}
    </>
}

export default Users
