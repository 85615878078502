import {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import GraphQLService from "Service/GraphQLService";
import {gql} from "@apollo/client";
import Answer from "./Answer";
import Stat from "./Stat";
import {EVENT_USER_INTERACT_VIEW_CHALLENGE} from "Event/EventType";
import useEvent from "Hook/useEvent";

import "./ChallengeView.scss"

const ChallengeView = () => {
    const [challengeId, setChallengeId] = useState(null)
    const [display, setDisplay] = useState(false)
    const [challenge, setChallenge] = useState({})

    useEvent(EVENT_USER_INTERACT_VIEW_CHALLENGE, e => {
        setChallengeId(e.data.challengeId)
        loadChallenge(e.data.challengeId)
    }, [challengeId])

    const loadChallenge = (challengeId) => {
        if (challengeId > 0) {
            GraphQLService.query(gql`
              query GetChallenge($challengeId: ID!) {
                  Challenge(id: $challengeId) {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    test {
                        id
                        title
                    }
                    answers {
                        id
                        question {
                            id
                            text
                        }
                        text
                        spyLog {
                            data
                        }
                        stats {
                            id
                            value
                            stat {
                                name
                                measure
                            }
                        }
                    }
                    stats {
                        id
                        value
                        stat {
                            name
                            measure
                        }
                    }
                  }
              }
            `, {challengeId})
                .then(result => {
                    setChallenge(result.data.Challenge)
                    setDisplay(true)
                })
                .catch((e) => {
                    console.error(e)
                })
        }
    }

    const onClose = () => {
        setDisplay(false)
    }

    if (!challengeId || !display) return null

    let num = 0;
    let list = challenge.answers.map(item => {
        num++
        return <Answer num={num} answer={item}  key={item.id} />
    })

    return <Modal show={display} onHide={onClose} animation={false} size={"lg"}>
        <Modal.Header closeButton>
            <Stat stat={challenge.stats} title="Статистика теста" buttonStyle={{position: "absolute", right: "50px"}} />
            <Modal.Title>{challenge.user.firstName} {challenge.user.lastName}, {challenge.test.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {list}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onClose} variant="light">
                Закрыть
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ChallengeView
