import Paginator from "Component/Paginator/Paginator"
import UserListItem from "./UserListItem"
import UserSetTest from "Component/UserSetTest/UserSetTest";
import {PAGE_SIZE} from "Config";

const UsersList = ({usersList, pageData, page, handlePageClick}) => {

    let count = 1
    const rows = usersList.map(item => {
        const num = page * PAGE_SIZE + count
        count++
        return <UserListItem
            user={item}
            num={num}
            key={item.id}
        />
    })

    let paginator = null
    if (pageData.totalCount > PAGE_SIZE) {
        paginator = <Paginator
            pageNum={page}
            onPageClick={handlePageClick}
            pageCount={Math.ceil(pageData.totalCount / PAGE_SIZE)}
        />
    }

    return <>
        <table className="table">
            <thead>
            <tr>
                <th>№</th>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>E-mail</th>
                <th>Регистрация</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>

        {paginator}

        <UserSetTest />
    </>
}

export default UsersList
