import SpyLog from "./SpyLog";
import MainEvent from "../Event/MainEvent";
import EventDispatcherService from "./EventDispatcherService";
import {EVENT_USER_INTERACT_STOP_ANSWER, EVENT_USER_INTERACT_STOP_ANSWER_COLOR} from "../Event/EventType";

class PlayService {
    playTimer

    answer

    mode = 'stop'

    playType = 'normal'

    speedMultiplier = 60

    play(ref, answer) {
        if (this.mode === 'play') return
        this.playType = 'normal'
        this.mode = 'play'
        this.answer = answer
        ref.innerText = ''

        let frameLog = SpyLog.parseData(answer.spyLog.data)

        let showFrameCallback = (frameLog, frameNum) => {
            let ops = frameLog[frameNum][1];
            ref.innerText = this.patch(ref.innerText, ops);
        };

        this.playFrames(showFrameCallback, frameLog);
    }

    stop() {
        if (this.mode === 'stop') return
        this.mode = 'stop'
        clearTimeout(this.playTimer);
        let event = new MainEvent(EVENT_USER_INTERACT_STOP_ANSWER, {answer: this.answer})
        EventDispatcherService.fire(event)
    }

    playColor(ref, answer) {
        if (this.mode === 'play') return
        this.playType = 'color'
        this.mode = 'play'
        this.answer = answer
        ref.innerText = ''
        ref.innerHTML = ''

        let frameLog = SpyLog.parseData(answer.spyLog.data)

        let keyEventsCount = 0;
        let isSlow = false;
        let slowCount = 0;

        let showFrameCallback = (frameLog, frameNum) => {
            let delay = frameLog[frameNum][0];
            let ops = frameLog[frameNum][1];
            keyEventsCount++;
            ref.innerHTML = this.patchColor(ref.innerHTML, ops, delay, keyEventsCount, isSlow, slowCount);
        };

        this.playFrames(showFrameCallback, frameLog);
    }

    stopColor() {
        if (this.mode === 'stop') return
        this.mode = 'stop'
        clearTimeout(this.playTimer);
        let event = new MainEvent(EVENT_USER_INTERACT_STOP_ANSWER_COLOR, {answer: this.answer})
        EventDispatcherService.fire(event)
    }

    playFrames(showFrameCallback, frameLog) {
        let i = 0;
        let nextFrameDelay = 0;
        const that = this;
        (function frame(showFrameCallback, frameLog, i) {
            showFrameCallback(frameLog, i);

            if (i < frameLog.length - 1) {
                i++;
                nextFrameDelay = frameLog[i][0] / that.speedMultiplier;
                that.playTimer = setTimeout(function(){
                    frame(showFrameCallback, frameLog, i);
                }, nextFrameDelay);
            } else {
                that.stop();
            }
        })(showFrameCallback, frameLog, i, that);
    }

    patch(str, operations) {
        if (typeof(str) !== 'string') {
            return '';
        }
        for (let operation of operations) {
            let m = operation.match(/^([+-])(\d+):([\s\S]+)$/);
            if (m) {
                let position = m[2];
                let action = m[1];
                let value = m[3];

                // eslint-disable-next-line no-eval
                eval(`value = '${value}'`)

                if (action === '-') {
                    str = str.substring(0, position) + str.substring(parseInt(position) + parseInt(value));
                }
                else if (action === '+') {
                    if (value === '\\n') {
                        value = "\n"
                    }
                    str = str.substring(0, position) + value + str.substring(position);
                }
            }
        }
        return str;
    }

    patchColor(str, operations, delay, keyEventsCount, isSlow, slowCount) {
        let slowCountMax = 10;
        let m;
        for (let operation_i in operations) {
            if (operations.hasOwnProperty(operation_i)) {
                m = operations[operation_i].match(/^([+-])(\d+):([\s\S]+)$/);
                if (m) {
                    let position = Number(m[2]);
                    let operation = m[1];
                    let value = m[3];

                    let vChars = []
                    if (str !== '') {
                        let iter = str.matchAll(/<span.*?<\/span>/g)
                        for (let item of iter) {
                            vChars.push(item)
                        }
                    }

                    if (operation === '-') {
                        delete vChars[position]
                    }
                    else if (operation === '+') {
                        let char_class = 'norm'

                        // eslint-disable-next-line no-eval
                        eval(`value = '${value}'`)

                        if (false === isSlow && (delay < 10 || value.length > 1)) {
                            char_class = 'fast';
                        } else if (keyEventsCount > 1 && (delay > 3000 || true === isSlow)) {
                            char_class = 'slow'
                            isSlow = true
                            slowCount++
                        }

                        if (value.match(/^[\s]+$/) && isSlow === true && slowCount > slowCountMax) {
                            isSlow = false
                        }

                        if  (value === "\n") {
                            value = '<br />'
                        }

                        const newEl = `<span class="${char_class}">${value}</span>`

                        vChars.splice(position, 0, newEl)
                    }

                    str = vChars.join('')
                }
            }
        }
        return str
    }
}

export default new PlayService()
