import PropTypes from 'prop-types'
import EntityService from "Service/EntityService";
import useConfirm from "Hook/useConfirm";

const DeleteButton = ({user}) => {
    const [confirmDeleteUser] = useConfirm(`Удалить пользователя ${user.firstName} ${user.lastName}?`, () => {
        deleteUser(user)
    })

    const handleDelete = () => {
        confirmDeleteUser()
    }

    const deleteUser = (user) => {
        EntityService.delete(user)
            .then(() => {})
            .catch(() => {})
    }

    return <input type="submit" value="Удалить" className="btn btn-danger btn-sm" onClick={handleDelete} />
}

DeleteButton.propTypes = {
    user: PropTypes.object.isRequired
}

export default DeleteButton
