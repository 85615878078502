import GraphQLService from "./GraphQLService";
import {gql} from "@apollo/client";
import EntityEvent from "../Event/EntityEvent";
import EventDispatcherService from "./EventDispatcherService";

class EntityService {
    add(entity) {
        return new Promise((resolve, reject) => {
            this._isTypeExists(entity)
            const entityType = entity.__typename
            const mutationType = 'Add' + entityType

            const setFields = this._objectToArgs(entity)
            const returnFields = this._objectToReturn(entity)

            const mutation = gql`
                mutation {
                    ${mutationType}(${setFields}) {
                        ${returnFields}
                    }
                }
            `
            this._mutate(entity, entityType, 'add', mutation)
                .then(res => {
                    resolve(res.data[mutationType])
                })
                .catch(() => {
                    reject()
                })
        })
    }

    update(entity) {
        return new Promise((resolve, reject) => {
            this._isEntity(entity)
            const entityType = entity.__typename
            const mutationType = 'Update' + entityType

            const setFields = this._objectToArgs(entity)
            const returnFields = this._objectToReturn(entity)

            const mutation = gql`
                mutation {
                    ${mutationType}(${setFields}) {
                        ${returnFields}
                    }
                }
            `
            this._mutate(entity, entityType, 'update', mutation)
                .then((res) => {
                    resolve(res.data[mutationType])
                })
                .catch(() => {
                    reject()
                })
        })
    }

    delete(entity) {
        return new Promise((resolve, reject) => {
            this._isEntity(entity)
            const entityType = entity.__typename
            const mutationType = 'Delete' + entityType
            const mutation = gql`
                mutation {
                    ${mutationType}(id: ${entity.id})
                }
            `
            this._mutate(entity, entityType, 'delete', mutation)
                .then(() => {
                    resolve()
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    /**
     * Сформировать из объекта строку аргументов мутации
     * @param {object} object
     * @returns {string}
     * @private
     */
    _objectToArgs(object) {
        const fields = Object.getOwnPropertyNames(object)
        let strings = []
        for (let fieldName of fields) {
            if (fieldName.match(/^__/)) continue

            if (Array.isArray(object[fieldName])) {
                let arrayStrings = []
                for (let i in object[fieldName]) {
                    if (!object[fieldName].hasOwnProperty(i)) continue
                    arrayStrings.push(this._oneLevelObjectString(object[fieldName][i]))
                }
                strings.push(`${fieldName}: [${arrayStrings.join(', ')}]`)
            } else if ('object' === typeof object[fieldName] && object[fieldName] !== null) {
                // TODO: тут тоже объект нужен
                strings.push(`${fieldName}: {id: ${object[fieldName].id}}`)
            } else {
                if (fieldName === 'id') {
                    strings.push(`${fieldName}: ${object[fieldName]}`)
                } else if (Number.isInteger(object[fieldName])) {
                    strings.push(`${fieldName}: ${object[fieldName]}`)
                } else {
                    strings.push(`${fieldName}: "${object[fieldName]}"`)
                }
            }
        }
        return strings.join(', ')
    }

    _objectToReturn(object) {
        const fields = Object.getOwnPropertyNames(object)
        let strings = []
        for (let fieldName of fields) {
            if (fieldName.match(/^__/)) continue
            if ('object' === typeof object[fieldName] && null !== object[fieldName]) {
                let innerFields
                let innerStrings = []
                let sampleObject

                if (Array.isArray(object[fieldName])) {
                    if ('undefined' !== typeof object[fieldName][0]) {
                        sampleObject = object[fieldName][0]
                    } else {
                        sampleObject = {id: ''}
                    }
                } else {
                    sampleObject = object[fieldName]
                }

                innerFields = Object.getOwnPropertyNames(sampleObject)
                //console.log('innerFields', sampleObject, innerFields)
                for (let innerFieldName of innerFields) {
                    if (innerFieldName.match(/^__/)) continue
                    if (this._isScalar(sampleObject[innerFieldName])) {
                        innerStrings.push(innerFieldName)
                    }
                }

                strings.push(`${fieldName} {${innerStrings.join("\n")}}`)
            } else {
                strings.push(fieldName)
            }
        }

        return strings.join("\n")
    }

    /**
     * Возвращает строку, представляющую первый уровень структуры объекта и только поля с скалярными значениями.
     * Нужно для формирования строки аргументов мутации gql
     *
     * @param {object} object
     * @returns {string}
     * @private
     */
    _oneLevelObjectString(object) {
        if (this._isScalar(object)) return ''

        let res = '{'
        let fieldStrings = []
        for (let key in object) {
            if (
                !object.hasOwnProperty(key) ||
                !this._isScalar(object[key]) ||
                key.match(/^__/)
            ) {
                continue
            }

            let val = object[key]
            if ('string' === typeof val) {
                val = val.replace(/[\n\r]+/g, '\\n')
                val = val.replace(/"/g, '\\"')
            }

            if ('id' !== key) {
                val = `"${val}"`
            }
            fieldStrings.push(`${key}: ${val}`)
        }
        res += fieldStrings.join(', ')
        res += '}'
        return res
    }

    /**
     * Является ли значение скалярным
     *
     * @param {*} val
     * @returns {boolean}
     * @private
     */
    _isScalar(val) {
        if (Array.isArray(val)) return false
        return !('object' === typeof val && null !== val);
    }

    /**
     *
     * @param {Object} entity
     * @throws {Error}
     * @private
     */
    _isEntity(entity) {
        this._isTypeExists(entity)
        if ('undefined' === typeof entity.id) {
            throw new Error('Not found id field in entity ' + JSON.stringify(entity))
        }
    }

    /**
     * Проверка, что установлено поле типа сущности в объекте.
     * Иначе бросает исключение (ничего не возвращает)
     *
     * @param {object} entity
     * @throws {Error} Если нет поля типа
     * @private
     */
    _isTypeExists(entity) {
        if ('undefined' === typeof entity.__typename) {
            throw new Error('Not found type field in entity ' + JSON.stringify(entity))
        }
    }

    _mutate(entity, entityType, operation, mutation) {
        return new Promise((resolve, reject) => {
            GraphQLService.mutation(mutation)
                .then(res => {
                    const event = new EntityEvent( {
                        entityType,
                        operation,
                        entity
                    })
                    EventDispatcherService.fire(event)
                    resolve(res)
                })
                .catch((e) => {
                    console.error('Failed mutate entity ' + entityType + ' ' + entity.id + ' ' + e)
                    reject(e)
                })
        })
    }
}

export default new EntityService()
