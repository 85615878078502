import {EVENT_USER_INTERACT_DELETE_CONFIRM} from "Event/EventType";
import MainEvent from "Event/MainEvent";
import EventDispatcherService from "Service/EventDispatcherService";

const useConfirm = (title, callback) => {
    return [() => {
        let text
        if ('object' === typeof title) {
            text = title?.text
            title = title?.title
        }
        const event = new MainEvent(EVENT_USER_INTERACT_DELETE_CONFIRM, {title, text, callback})
        EventDispatcherService.fire(event)
    }]
}

export default useConfirm
