class AuthService {
    keyName = 'token'

    isAuthenticated() {
        return !!this.getToken()
    }

    auth(credentials) {
        return new Promise((resolve, reject) => {
            let url = window.location.protocol + '//' + window.location.hostname + '/authentication_token'
            //fetch(process.env.REACT_APP_SERVER_ADDRESS + 'authentication_token', {
            fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            })
                .then(res => {
                    if (res.ok) {
                        res.json().then(resJson => {
                            if ('undefined' !== typeof resJson.token) {
                                this.setToken(resJson.token)
                                resolve()
                            }
                        })
                    } else {
                        reject('Authentication failed')
                    }
                })
                .catch(e => {
                    reject('Authentication failed')
                })
        })
    }

    setToken(token) {
        localStorage.setItem(this.keyName, token)
    }

    getToken() {
        return localStorage.getItem(this.keyName)
    }

    logout() {
        localStorage.removeItem(this.keyName)
    }
}

export default new AuthService()
