import EventSubscribersStore from "./EventSubscribersStore";

class EventDispatcherService {
    subscribers = {}

    /**
     *
     * @param {object} event
     */
    fire(event) {
        let callbacks = EventSubscribersStore.get(event.eventName)

        for (let callback of callbacks) {
            callback(event)
        }
    }

    subscribe(eventType, callback) {
        EventSubscribersStore.add(eventType, callback)
    }

    unsubscribe(eventType, callback) {
        EventSubscribersStore.remove(eventType, callback)
    }
}

export default new EventDispatcherService()
