class MainEvent {
    _eventName = ''

    _data = []

    constructor(eventName, data= {}) {
        this._eventName = eventName
        this._data = data
    }

    get data() {
        return this._data
    }

    get eventName() {
        return this._eventName
    }
}

export default MainEvent
