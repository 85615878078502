class SpyLog {
    parseData(data) {
        let parsedData = null
        let log = data
            .replace(/&quot;/g, '')
            .replace(/\\/g, '\\\\')
            .replace(/'/g, '\\\\\'')
            .replace(/\n/g, '\\n')
            .replace(/\t/g, '\\t');

        // Экранирование кавычек во вставках пользователя
        // TODO: Сделать при вводе, а не здесь!!!
        function replacer(m, p1, p2, p3, p4, p5) {
            p3 = p3.replace(/"/g, '\\"');
            return p1 + p2 + p3 + p4 + p5;
        }
        log = log.replace(/(",|,\[)("\+\d+:)(.*?)(")(,\[|\]\])/g, replacer);

        try {
            parsedData = JSON.parse(log.toString());
        } catch (e) {
            console.log(log);
            console.log(e);
        }
        return parsedData
    }

    getList = (list) => {
        let a = [];
        for (let i in list) {
            if (list.hasOwnProperty(i)) {
                a.push([+i / 1000, list[i]]);
            }
        }
        return a;
    }

    countLogChars(data) {
        let log = this.parseData(data)

        let step = 100;
        let count = [];
        let focus = [];
        let timePos = 0;
        let currentCount = 0;
        let focusFrom = 0;
        let m;

        for (let i in log) {
            if (log.hasOwnProperty(i) && typeof log[i][1] !== 'undefined') {
                let time = log[i][0];
                let event = log[i][1][0];

                timePos += time;

                let timeIndex = Math.floor(timePos / step) * step;

                if ('undefined' === typeof event) continue

                m = event.match(/([+-])(\d+):([\s\S]+)/)
                if (m) {
                    let operation = m[1];
                    let value = m[3];

                    if (operation === '+') {
                        currentCount += value.length;
                    }
                    else if (operation === '-') {
                        currentCount -= value;
                    }
                    count[timeIndex] = currentCount;
                } else {
                    m = event.match(/([+-])f/)
                    if (m) {
                        if (m[1] === '+' && timeIndex > focusFrom) {
                            focus.push({
                                from: focusFrom / 1000,
                                to: timeIndex / 1000,
                                color: '#EEEEEE'
                            });
                        } else if (m[1] === '-') {
                            focusFrom = timeIndex;
                        }
                    }
                }
            }
        }

        return {
            counts: count,
            focus: focus
        };
    }

    getGraphDataSpeed(data) {

        let log = this.parseData(data)

        let step = 100;
        let count = {add: [], del: []};
        let focus = [];
        let timePos = 0;
        let focusFrom = 0;
        let m;

        let timeStack = {
            add: [],
            del: []
        };

        function getAvgSpeed(timeStack, time) {
            let summ = 0;
            let start = time - 1000;
            for (let i in timeStack) {
                if (timeStack.hasOwnProperty(i)) {
                    if (i >= start && i <= time) {
                        summ += +timeStack[i];
                    }
                }
            }

            return graphLog(summ);
        }

        function graphLog(x) {
            if (x > 0) {
                return Math.log(x + 1);
            }
            return 0;
        }

        for (let log_i in log) {
            if (log.hasOwnProperty(log_i) && typeof log[log_i][1] !== 'undefined') {
                let time = log[log_i][0];
                let event = log[log_i][1][0];

                timePos += time;

                let timeIndex = Math.floor(timePos / step) * step;

                m = event.match(/([+-])(\d+):([\s\S]+)/)
                if (m) {
                    let operation = m[1];
                    let value = m[3];

                    if (operation === '+') {
                        timeStack.add[timePos] = value.length;
                    }
                    else if (operation === '-') {
                        timeStack.del[timePos] = +value;
                    }

                    let spdAdd = getAvgSpeed(timeStack.add, timePos);
                    let spdDel = getAvgSpeed(timeStack.del, timePos);
                    count.add.push([timePos / 1000, spdAdd]);
                    count.del.push([timePos / 1000, -spdDel]);


                } else {
                    m = event.match(/([+-])f/)
                    if (m) {
                        if (m[1] === '+' && timeIndex > focusFrom) {
                            focus.push({
                                from: focusFrom / 1000,
                                to: timeIndex / 1000,
                                color: '#EEEEEE'
                            });
                        } else if (m[1] === '-') {
                            focusFrom = timeIndex;
                        }
                    }
                }
            }
        }

        return {
            add: count.add,
            del: count.del,
            focus: focus
        };
    }

    getAnswerTimeMs(data) {
        let log = this.parseData(data)
        let time = 0;
        for (let i in log) {
            if (log.hasOwnProperty(i)) {
                time += log[i][0];
            }
        }
        return time
    }

    getAnswerTime(data) {
        const time = this.getAnswerTimeMs(data)
        if ('number' === typeof time) {
            return this.timeFormat(time);
        }
        return '-'
    }

    timeFormat(ms) {
        let min = Math.floor(ms / 1000 / 60);
        let rest = ms - min * 1000 * 60;
        let sec = Math.floor(rest / 1000);
        return this._lpad(min) + ':' + this._lpad(sec);
    }

    _lpad(num) {
        if (num < 10) {
            num = '0' + num;
        }
        return num;
    }
}

export default new SpyLog()
