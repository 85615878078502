import MainEvent from "Event/MainEvent";
import {EVENT_USER_INTERACT_USER_EDIT} from "Event/EventType";
import EventDispatcherService from "Service/EventDispatcherService";

const ButtonAddUser = () => {
    const handleUserAddOpen = (e) => {
        const event = new MainEvent(EVENT_USER_INTERACT_USER_EDIT, {})
        EventDispatcherService.fire(event)
    }

    return <span
        className="btn btn-success navbar-right"
        onClick={handleUserAddOpen}
    >Добавить нового соискателя</span>
}

export default ButtonAddUser
