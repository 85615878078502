import MainEvent from "./MainEvent";
import {EVENT_ENTITY} from "./EventType";

class EntityEvent extends MainEvent {
    constructor(data= {}) {
        super(EVENT_ENTITY, data);
    }

    isEntity(entityTypes) {
        if ('undefined' === this._data.entityType) return false
        if (!Array.isArray(entityTypes)) entityTypes = [entityTypes]
        for (let typeName of entityTypes) {
            if (typeName === this._data.entityType) {
                return true
            }
        }
        return false
    }

    isOperation(operations) {
        if ('undefined' === this._data.operation) return false
        if (!Array.isArray(operations)) operations = [operations]
        for (let operation of operations) {
            if (operation === this._data.operation) {
                return true
            }
        }
        return false
    }
}

export default EntityEvent
