import { ApolloClient, InMemoryCache, concat, HttpLink, ApolloLink } from "@apollo/client"
import AuthService from "./AuthService"
import { onError } from 'apollo-link-error'
import MainEvent from "../Event/MainEvent";
import {EVENT_API_REQUEST} from "../Event/EventType";
import EventDispatcherService from "./EventDispatcherService";

class GraphQLService {
    //serverUrl = process.env.REACT_APP_SERVER_ADDRESS + 'graphql/'
    serverUrl = window.location.protocol + '//' + window.location.hostname + '/graphql/'

    client
    cache

    constructor() {
        const logoutLink = onError(({ networkError }) => {
            if (networkError && networkError.statusCode && networkError.statusCode === 401) {
                AuthService.logout()
                window.location.reload()
            }
        })

        const httpLink = new HttpLink({ uri: this.serverUrl });

        const authMiddleware = new ApolloLink((operation, forward) => {
            operation.setContext({
                headers: {
                    authorization: 'Bearer ' + AuthService.getToken(),
                }
            })

            return forward(operation)
        })

        this.cache = new InMemoryCache()

        this.client = new ApolloClient({
            cache: this.cache,
            redirect: 'error',
            link: logoutLink.concat(concat(authMiddleware, httpLink)),
        })
    }

    identify(type, id) {
        return this.cache.identify({id: id, __typename: type})
    }

    /**
     *
     * @param {gql} query
     * @param {object=} variables
     * @param {boolean=} force - Принудительно перезапросить с бэка
     * @returns {Promise<null>}
     */
    async query(query, variables, force) {
        if ('object' !== typeof variables) {
            variables = {}
        }

        let fetchPolicy = 'cache-first'
        if ('boolean' === typeof force && force) {
            fetchPolicy = 'network-only'
        }
        const eventStart = new MainEvent(EVENT_API_REQUEST, {action: 'start'})
        EventDispatcherService.fire(eventStart)

        let res = null

        try {
            res = await this.client
                .query({
                    query: query,
                    fetchPolicy: fetchPolicy,
                    variables
                })
        } finally {
            this.fireApiEndEvent()
        }

        return res
    }

    fireApiEndEvent() {
        const eventEnd = new MainEvent(EVENT_API_REQUEST, {action: 'end'})
        EventDispatcherService.fire(eventEnd)
    }

    async mutation(mutation) {
        return await this.client
            .mutate({
                mutation: mutation
            })
    }

    async reset() {
        return this.client.resetStore()
    }
}

export default new GraphQLService()
