import { Form, Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import {useMemo, useState} from "react"
import GraphQLService from "Service/GraphQLService"
import {gql} from "@apollo/client"
import EntityService from "Service/EntityService";
import {EVENT_USER_INTERACT_SAVE, EVENT_USER_INTERACT_USER_EDIT} from "Event/EventType";
import useEvent from "Hook/useEvent";

const UserEdit = ({onUpdate}) => {

    const emptyUser = useMemo(() => {
        return {
            __typename: 'User',
            firstName: '',
            middleName: '',
            lastName: '',
            email: ''
        }
    }, [])

    const [user, setUser] = useState(emptyUser)
    const [display, setDisplay] = useState(false)

    useEvent(EVENT_USER_INTERACT_USER_EDIT, (e) => {
            let user
            if (e.data.user) {
                user = e.data.user
            } else {
                user = emptyUser
            }
            setUser(user)
            if (user.id) {
                loadUser(user.id)
            } else {
                setDisplay(true)
            }
        }, [user, emptyUser])

    // Обработка кнопки Save
    useEvent(EVENT_USER_INTERACT_SAVE, () => {if (display) saveUser()})

    const loadUser = (userId) => {
        if (userId > 0) {
            GraphQLService.query(gql`
              query GetUser($userId: ID!) {
                  User(id: $userId) {
                    id
                    firstName
                    middleName
                    lastName
                    email
                  }
              }
            `, {userId})
                .then(result => {
                    setUser(result.data.User)
                    setDisplay(true)
                })
                .catch((e) => {
                    console.error(e)
                })
        }
    }

    if (!display) return null

    const saveUser = event => {
        event.preventDefault()

        let isValid = isValidLastName() &&
            isValidFirstName() &&
            isValidEmail()

        if (!isValid) return

        let method = 'add'
        if (user.id) {
            method = 'update'
        }
        EntityService[method](user)
            .then(() => {
                onEditorClose()
                if (onUpdate) onUpdate()
            })
    }

    const onEditorClose = () => {
        setDisplay(false)
    }

    const isValidFirstName= () => {
        if (!user.firstName) return false
        return true
    }

    const isValidLastName = () => {
        if (!user.lastName) return false
        return true
    }

    const isValidEmail = () => {
        if (!user.email) return false
        // eslint-disable-next-line no-control-regex
        if (!user.email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) {
            return false
        }

        return true
    }

    const handleChangeFirstName= (e) => {
        setUser({...user, firstName: e.target.value})
    }

    const handleChangeMiddleName = (e) => {
        setUser({...user, middleName: e.target.value})
    }

    const handleChangeLastName = (e) => {
        setUser({...user, lastName: e.target.value})
    }

    const handleChangeEmail = (e) => {
        setUser({...user, email: e.target.value})
    }

    // animation={false} т.к. иначе ругается реакт на "findDOMNode is deprecated in StrictMode"
    return <Modal show={display} onHide={onEditorClose} animation={false} centered={true}>
            <Form noValidate onSubmit={saveUser}>
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            value={user.lastName}
                            placeholder="Фамилия"
                            isValid={isValidLastName()}
                            isInvalid={!isValidLastName()}
                            onChange={handleChangeLastName}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                            type="text"
                            value={user.firstName}
                            placeholder="Имя"
                            isValid={isValidFirstName()}
                            isInvalid={!isValidFirstName()}
                            onChange={handleChangeFirstName}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Отчество</Form.Label>
                        <Form.Control
                            type="text"
                            value={user.middleName ?? ''}
                            placeholder="Отчество"
                            onChange={handleChangeMiddleName}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="text"
                            value={user.email}
                            placeholder="E-mail"
                            isValid={isValidEmail()}
                            isInvalid={!isValidEmail()}
                            onChange={handleChangeEmail}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onEditorClose} variant="light">
                        Закрыть
                    </Button>
                    <Button type={"submit"} variant="primary">
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
}

UserEdit.propTypes = {
    onUpdate: PropTypes.func.isRequired
}

export default UserEdit
