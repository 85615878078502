import EventDispatcherService from "Service/EventDispatcherService";
import MainEvent from "Event/MainEvent";
import {EVENT_USER_INTERACT_VIEW_CHALLENGE} from "Event/EventType";
import {formatDate} from "Service/Utils";

const ChallengesListItem = ({num, challenge}) => {

    const handleChallengeView = (challengeId) => {
        const event = new MainEvent(EVENT_USER_INTERACT_VIEW_CHALLENGE, {challengeId})
        EventDispatcherService.fire(event)
    }

    let challengeString
    if (null === challenge.dateStart) {
        challengeString = challenge.test.title
    } else {
        challengeString = <a href="/" onClick={(e) => { e.preventDefault(); handleChallengeView(challenge.id) }}>{challenge.test.title}</a>
    }

    return <tr>
        <td>{num}</td>
        <td>{challenge.user.lastName} {challenge.user.firstName}</td>
        <td>{formatDate(challenge.user.dateAdd)}</td>
        <td>{challengeString}</td>
        <td>{document.location.origin}/self/{challenge.code}</td>
        <td>{formatDate(challenge.dateStart)}</td>
        <td>{formatDate(challenge.dateEnd)}</td>
    </tr>
}

export default ChallengesListItem
