import EventDispatcherService from "Service/EventDispatcherService";
import {useEffect} from "react";

/**
 *
 * @param {string|array} eventTypes
 * @param {function} callback
 * @param {null|array} deps
 */
const useEvent = (eventTypes, callback, deps=undefined) => {
    if ('string' === typeof eventTypes) {
        eventTypes = [eventTypes]
    }

    useEffect(() => {
        for (let eventType of eventTypes) {
            EventDispatcherService.subscribe(eventType, callback)
        }
        return () => {
            for (let eventType of eventTypes) {
                EventDispatcherService.unsubscribe(eventType, callback)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}

export default useEvent
