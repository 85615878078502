import {useState} from "react";
import {Button, Modal} from "react-bootstrap";

const Stat = ({stat, text, title, buttonStyle}) => {
    const [display, setDisplay] = useState(false)

    if (stat.length === 0) return null

    const onButtonClick = () => {
        setDisplay(true)
    }

    const onClose = () => {
        setDisplay(false)
    }

    let list = stat.map(item => {
        let value = item.value
        if (!Number.isInteger(value)) {
            value = item.value.toFixed(2)
        }
        return <li key={item.id}><span className="text-muted">{item.stat.name}:</span> <span className="text-primary">{value} {item.stat.measure}</span></li>
    })

    return <>
        <button
            type="button"
            className="btn btn-primary"
            onClick={onButtonClick}
            style={buttonStyle}
        >{title}</button>

        <Modal show={display} onHide={onClose} animation={false} size={"sm"}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
                <ul>
                    {list}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="light">
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default Stat
