import Paginator from "Component/Paginator/Paginator"
import TestsListItem from "./TestsListItem";
import {PAGE_SIZE} from "Config";

const TestsList = ({testsList, pageData, page, handlePageClick}) => {

    let count = 1
    const rows = testsList.map(item => {
        const num = page * PAGE_SIZE + count
        count++
        return <TestsListItem
            test={item}
            num={num}
            key={item.id}
        />
    })

    let paginator = null
    if (pageData.totalCount > PAGE_SIZE) {
        paginator = <Paginator
            pageNum={page}
            onPageClick={handlePageClick}
            pageCount={Math.ceil(pageData.totalCount / PAGE_SIZE)}
        />
    }

    return <>
        <table className="table">
            <thead>
            <tr>
                <th>№</th>
                <th>Название</th>
                <th>Ссылка для самотестирования</th>
                <th>Действия</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>

        {paginator}
    </>
}

export default TestsList
