import PropTypes from 'prop-types'
import TestDelete from "./TestDelete";
import MainEvent from "Event/MainEvent";
import {EVENT_USER_INTERACT_TEST_EDIT, EVENT_USER_INTERACT_TEST_VIEW} from "Event/EventType";
import EventDispatcherService from "Service/EventDispatcherService";

const TestsListItem = ({num, test}) => {

    const onClickEdit = (e) => {
        e.preventDefault()
        const event = new MainEvent(EVENT_USER_INTERACT_TEST_EDIT, {test})
        EventDispatcherService.fire(event)
    }

    const onClickView = (e) => {
        e.preventDefault()
        const event = new MainEvent(EVENT_USER_INTERACT_TEST_VIEW, {test})
        EventDispatcherService.fire(event)
    }

    return <tr>
        <td>{num}</td>
        <td><a href="/" onClick={onClickView}>{test.title}</a></td>
        <td>{document.location.origin}/self/{test.id}</td>
        <td>
            <a href="/" onClick={onClickEdit} className="btn btn-primary btn-sm">Редактировать</a>&nbsp;
            <TestDelete test={test} />
        </td>
    </tr>
}

TestsListItem.propTypes = {
    num: PropTypes.number.isRequired,
    test: PropTypes.object.isRequired,
    handleTestEdit: PropTypes.func
}

export default TestsListItem
