import PropTypes from 'prop-types'
import DeleteButton from "./DeleteButton";
import MainEvent from "Event/MainEvent";
import {EVENT_USER_INTERACT_USER_EDIT, EVENT_USER_INTERACT_SET_TEST, EVENT_USER_INTERACT_USER_VIEW} from "Event/EventType";
import EventDispatcherService from "Service/EventDispatcherService";
import {formatDate} from "Service/Utils";

const UserListItem = ({num, user}) => {

    const onClickEdit = (e) => {
        e.preventDefault()
        const event = new MainEvent(EVENT_USER_INTERACT_USER_EDIT, {user})
        EventDispatcherService.fire(event)
    }

    const onClickView = (e) => {
        e.preventDefault()
        const event = new MainEvent(EVENT_USER_INTERACT_USER_VIEW, {user})
        EventDispatcherService.fire(event)
    }

    const onClickSetTest= (e) => {
        e.preventDefault()
        const event = new MainEvent(EVENT_USER_INTERACT_SET_TEST, {user})
        EventDispatcherService.fire(event)
    }

    return <tr>
        <td>{num}</td>
        <td><a href="/" onClick={onClickView}>{user.lastName}</a></td>
        <td><a href="/" onClick={onClickView}>{user.firstName}</a></td>
        <td><a href="/" onClick={onClickView}>{user.email}</a></td>
        <td>{formatDate(user.dateAdd)}</td>
        <td>
            <a href="/" onClick={onClickSetTest} className="btn btn-success btn-sm">Назначить тест</a>&nbsp;
            <a href="/" onClick={onClickEdit} className="btn btn-primary btn-sm">Редактировать</a>&nbsp;
            <DeleteButton user={user} />
        </td>
    </tr>
}

UserListItem.propTypes = {
    num: PropTypes.number.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string
    }).isRequired
}

export default UserListItem
