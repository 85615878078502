import TopMenu from "./TopMenu"
import Users from "./Users/Users"
import Tests from "./Tests/Tests"
import Challenges from "./Challenges/Challenges"
import LoadIndicator from "Component/LoadIndicator/LoadIndicator"
import {
    Route,
    Switch,
    Redirect,
    withRouter
} from "react-router-dom"
import ChallengeView from "./ChallengeView/ChallengeView";
import TestView from "./TestView/TestView";
import Confirm from "./Confirm/Confirm";

const Main = () =>
    <>
        <LoadIndicator />
        <TopMenu />
        <br />
        <Switch>
            <Route path='/users' component={Users} />
            <Route path='/tests' component={Tests} />
            <Route path='/challenges' component={Challenges} />
            <Redirect from='/' to='/users' />
        </Switch>

        <ChallengeView />
        <TestView />
        <Confirm />
    </>

export default withRouter(Main)
