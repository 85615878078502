import {Button, Form, Modal} from "react-bootstrap";
import ButtonSave from "Component/Button/ButtonSave";
import { useReducer, useState} from "react";
import GraphQLService from "Service/GraphQLService";
import {gql} from "@apollo/client";
import EntityService from "Service/EntityService";
import {EVENT_USER_INTERACT_SAVE, EVENT_USER_INTERACT_SET_TEST} from "Event/EventType";
import useEvent from "../../Hook/useEvent";

const UserSetTest = () => {
    const [user, setUser] = useState({})
    const [testsAll, setTestsAll] = useState([])
    const [testId, setTestId] = useState(null)

    const [state, dispatch] = useReducer((state, action) =>{
        switch (action.type) {
            case 'loadedUser':
                state.loadedUser = true
                break
            case 'loadedTests':
                state.loadedTests = true
                break
            case 'close':
                state.loadedUser = false
                state.loadedTests = false
                break
            default:
                throw new Error();
        }
        state.display = state.loadedUser && state.loadedTests
        return {...state}
    }, {display: false, loadedUser: false, loadedTests: false})

    useEvent(EVENT_USER_INTERACT_SET_TEST, e => {
        let user = e.data.user
        setUser(user)
        loadUser(user.id)
        loadTests(user.id)
    }, [user])

    // Обработка кнопки Save
    useEvent(EVENT_USER_INTERACT_SAVE, () => {
        if (state.display && user.id > 0 && testId > 0) {
            setTest(user.id, testId)
        }
    })

    const loadUser = (userId, force) => {
        if (userId > 0) {
            GraphQLService.query(gql`
              query GetUserForSetTest($userId: ID!) {
                  User(id: $userId) {
                    id
                    firstName
                    lastName
                    challenges {
                      id,
                      test {id}
                    }
                  }
              }
            `, {userId}, force)
                .then(result => {
                    setUser(result.data.User)
                    dispatch({type: 'loadedUser'})
                })
                .catch((e) => {
                    console.error(e)
                })
        }
    }

    const loadTests = (userId, force) => {
        GraphQLService.query(gql`
              query GetTestsForSet($page: Int!) {
                  Tests(page: $page) {
                      tests {
                        id,
                        title
                      }
                      totalCount
                  }
              }
            `, {page: 1}, force)
            .then(result => {
                setTestsAll(result.data.Tests.tests)
                dispatch({type: 'loadedTests'})
            })
            .catch((e) => {
                console.error(e)
            })
    }

    if (!state.display) return null

    const setTest = (userId, testId) => {
        let challenge = {
            __typename: 'Challenge',
            user: {
                __typename: 'User',
                id: userId
            },
            test: {
                __typename: 'Test',
                id: parseInt(testId)
            }
        }

        EntityService.add(challenge)
            .then(() => {
                loadUser(userId, true)
                onClose()
            })
    }

    const onTestChange = (e) => {
        setTestId(e.target.value)
    }

    const testSelectorIsValid = () => {
        return testId && testId > 0
    }

    const onClose = () => {
        dispatch({type: 'close'})
    }

    if (user?.id) {
        let testsOptions = []

        let testSelector = 'Нет доступных тестов'

        if (user.challenges) {
            let testsAvailable = testsAll.filter((test) => {
                return -1 === user.challenges.findIndex(challenge => {
                    return challenge.test.id === test.id
                })
            })

            if (testsAvailable.length > 0) {
                testsOptions = testsAvailable.map((item) => {
                    return <option value={item.id} key={item.id}>{item.title}</option>
                })

                testSelector = <Form.Group
                    controlId="formTest"
                >
                    <Form.Label>Тест</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={onTestChange}
                        required
                        isValid={testSelectorIsValid()}
                    >
                        <option value="" key="0">Выберите тест:</option>
                        {testsOptions}
                    </Form.Control>
                    <Form.Control.Feedback />
                </Form.Group>
            }
        }

        // animation={false} т.к. иначе ругается реакт на "findDOMNode is deprecated in StrictMode"
        return <Modal show={state.display} onHide={onClose} animation={false} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Назначение теста</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{user.lastName} {user.firstName}</h4>
                <form>
                    {testSelector}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="light">
                    Закрыть
                </Button>
                <ButtonSave />
            </Modal.Footer>
        </Modal>
    }
    return null
}

export default UserSetTest
