import {useEffect, useRef, useState} from "react";
import GraphQLService from "Service/GraphQLService";
import {gql} from "@apollo/client";
import IsLoadingError from "Component/Users/IsLoadingError";
import IsLoading from "Component/Users/IsLoading";
import TestsList from "Component/Tests/TestsList";
import {EVENT_ENTITY, EVENT_USER_INTERACT_SEARCH} from "Event/EventType";
import ButtonAddTest from "./ButtonAddTest";
import TestEdit from "Component/TestEdit/TestEdit";
import Search from "Component/Search/Search";
import useEvent from "Hook/useEvent";

const Tests = () => {
    const [pageData, setPageData] = useState({
        totalCount: 0,
        list: []
    })

    const [page, setPage] = useState(0)
    const [find, setFind] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingError, setIsLoadingError] = useState(false)

    const currentPage = useRef(page)
    useEffect(() => {
        currentPage.current = page
    })

    useEffect(() => {
        loadPage(page, find)
    }, [page, find]);

    useEvent(EVENT_ENTITY, event => {
        if (event.isEntity('Test') && event.isOperation(['add', 'delete'])) {
            GraphQLService.reset()
                .then(() => {
                    loadPage(currentPage.current, find)
                })
        }
    }, [find, page])

    useEvent(EVENT_USER_INTERACT_SEARCH, event => {
        setFind(event.data.find)
        setPage(0)
    })

    const loadPage = (page, find) => {
        setIsLoading(true)
        setIsLoadingError(false)
        GraphQLService.query(gql`
              query GetTests($page: Int!, $find: String) {
                  Tests(page: $page, find: $find) {
                      tests {
                        id,
                        title
                      }
                      totalCount
                  }
              }
            `, {page: page+1, find: find})
            .then(result => {
                setIsLoading(false)
                setPageData({
                    totalCount: result.data.Tests.totalCount,
                    list: result.data.Tests.tests
                })
            })
            .catch((e) => {
                setIsLoadingError(true)
                console.error(e)
            })
    }

    const handlePageClick = (data) => setPage(data.selected)

    const onUpdate = () => loadPage(page, find)

    let listContent

    if (isLoadingError) {
        listContent = <IsLoadingError />
    } else {
        if (isLoading) {
            listContent = <IsLoading />
        } else {
            listContent = <TestsList
                page={page} handlePageClick={handlePageClick} pageData={pageData}
                testsList={pageData.list} onUpdate={onUpdate} />
        }
    }

    return <>
        <h1>Тесты</h1>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="collapse navbar-collapse">
                <Search />
                <ButtonAddTest />
            </div>
        </nav>

        {listContent}

        <TestEdit onUpdate={onUpdate} />
    </>
}

export default Tests
