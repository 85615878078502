import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import DeleteQuestion from "./DeleteQuestion/DeleteQuestion";

const Question = ({question, onChange, onDelete}) => {
    const [editQuestion, setEditQuestion] = useState({})

    useEffect(() => {
        setEditQuestion(question)
    }, [question])

    const handleChangeText = (e) => {
        const updatedQuestion = {...editQuestion, text: e.target.value}
        setEditQuestion(updatedQuestion)
        onChange(updatedQuestion)
    }

    return <li className="question">
        <DeleteQuestion question={question} onDelete={onDelete} />
        <div className="form-group" style={{marginRight: '15px'}}>
            <textarea
                required="required"
                className="form-control"
                value={editQuestion.text}
                onChange={handleChangeText}
            />
        </div>
    </li>
}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
}

export default Question
