import {useCallback, useRef, useState} from "react";
import PlayService from "Service/PlayService";
import SpyLog from "Service/SpyLog";
import {
    EVENT_USER_INTERACT_PLAY_ANSWER,
    EVENT_USER_INTERACT_PLAY_ANSWER_COLOR,
    EVENT_USER_INTERACT_STOP_ANSWER,
    EVENT_USER_INTERACT_STOP_ANSWER_COLOR
} from "Event/EventType";
import useEvent from "Hook/useEvent";

const TimeInformer = ({answer}) => {

    const playTimeTimer = useRef(null);
    const timeStart = useRef(0);

    let timeAll = SpyLog.getAnswerTimeMs(answer.spyLog.data)

    const [timeCurrent, setTimeCurrent] = useState(timeAll)

    const getPlayedTimestamp = useCallback(() => {
        let passedTime = ((new Date()).getTime() - timeStart.current) * PlayService.speedMultiplier
        if (passedTime > timeAll) {
            passedTime = timeAll
        }
        return passedTime
    }, [timeAll])

    useEvent([EVENT_USER_INTERACT_PLAY_ANSWER, EVENT_USER_INTERACT_PLAY_ANSWER_COLOR], e => {
        if (e.data.answer.id !== answer.id) return
        timeStart.current = (new Date()).getTime()
        let period = 100

        playTimeTimer.current = setInterval(() => {
            setTimeCurrent(getPlayedTimestamp())
        }, period);
    }, [answer.id, getPlayedTimestamp])

    useEvent([EVENT_USER_INTERACT_STOP_ANSWER, EVENT_USER_INTERACT_STOP_ANSWER_COLOR], e => {
        if (e.data.answer.id !== answer.id) return
        clearInterval(playTimeTimer.current);
        setTimeCurrent(getPlayedTimestamp())
    }, [answer.id, getPlayedTimestamp])

    return <span className="answer_time">
        <span>{SpyLog.timeFormat(timeCurrent)}</span> / <span>{SpyLog.timeFormat(timeAll)}</span>
    </span>
}

export default TimeInformer
