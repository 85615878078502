import SpyLog from "../../Service/SpyLog";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const GraphSpeed = ({data}) => {
    //console.log('data', data)
    let graphData = SpyLog.getGraphDataSpeed(data)
    //console.log(graphData)

    const options = {

        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: false
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                }
            },
            series: {
                animation: false
            }
        },

        xAxis: {
            plotBands: graphData.focus,
            title1: {
                text: 'sec'
            }
        },

        series: [{
            name: 'Chars+',
            data: graphData.add
        },
            {
                name: 'Chars-',
                data: graphData.del
            }]

    }

    return <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{className: "graph"}}
        />
}

export default GraphSpeed
