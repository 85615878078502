import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStop} from "@fortawesome/free-solid-svg-icons";
import MainEvent from "../../Event/MainEvent";
import EventDispatcherService from "../../Service/EventDispatcherService";
import {EVENT_USER_INTERACT_STOP_ANSWER} from "../../Event/EventType";

const ButtonStop = ({answer}) => {

    const onButtonStop = () => {
        const event = new MainEvent(EVENT_USER_INTERACT_STOP_ANSWER, {answer: answer})
        EventDispatcherService.fire(event)
    }

    return <button
        type="button"
        className="btn btn-light"
        onClick={onButtonStop}
    ><FontAwesomeIcon icon={faStop} /> Play </button>
}

export default ButtonStop
