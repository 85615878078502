export const EVENT_ENTITY = 'entity.event'
export const EVENT_USER_INTERACT_SAVE = 'user.interact.save'
export const EVENT_USER_INTERACT_PLAY_ANSWER = 'user.interact.play-answer'
export const EVENT_USER_INTERACT_STOP_ANSWER = 'user.interact.stop-answer'
export const EVENT_USER_INTERACT_PLAY_ANSWER_COLOR = 'user.interact.play-answer-color'
export const EVENT_USER_INTERACT_STOP_ANSWER_COLOR = 'user.interact.stop-answer-color'
export const EVENT_USER_INTERACT_VIEW_CHALLENGE = 'user.interact.view-challenge'
export const EVENT_USER_INTERACT_TEST_EDIT = 'user.interact.test-edit'
export const EVENT_USER_INTERACT_USER_EDIT = 'user.interact.user-edit'
export const EVENT_USER_INTERACT_SET_TEST = 'user.interact.set-test'
export const EVENT_USER_INTERACT_USER_VIEW = 'user.interact.user-view'
export const EVENT_USER_INTERACT_TEST_VIEW = 'user.interact.test-view'
export const EVENT_USER_INTERACT_SEARCH = 'user.interact.search'
export const EVENT_USER_INTERACT_DELETE_CONFIRM = 'user.interact.delete-confirm'
export const EVENT_API_REQUEST = 'event.api.request'