import {useState} from "react";
import {EVENT_API_REQUEST} from "Event/EventType";
import {Spinner} from "react-bootstrap";
import useEvent from "Hook/useEvent";

import "./LoadIndicator.scss"

const LoadIndicator = () => {
    const [count, setCount] = useState(0)

    useEvent(EVENT_API_REQUEST, e => {
        if (e.data.action === 'start') {
            setCount(count + 1)
        } else {
            setCount(count - 1)
        }
    }, [count])

    if (count > 0) {
        return <div className="load-indicator"><Spinner animation="border" variant="success" /></div>
    }

    return null
}

export default LoadIndicator
