import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import useConfirm from "Hook/useConfirm";

import "./DeleteQuestion.scss"

const DeleteQuestion = ({question, onDelete}) => {

    const [confirmDeleteUser] = useConfirm({
            title: `Удалить вопрос?`,
            text: question.text
        }, () => {
        onDelete(question)
    })

    const handleDeleteQuestion = (e) => {
        confirmDeleteUser()
    }

    return <button
        type="button"
        className="close delete-question-button"
        title="Удалить вопрос"
        onClick={handleDeleteQuestion}
    ><FontAwesomeIcon icon={faTimes} /></button>
}

export default DeleteQuestion
