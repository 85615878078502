import {useState} from "react";
import MainEvent from "Event/MainEvent";
import {EVENT_USER_INTERACT_SEARCH} from "Event/EventType";
import EventDispatcherService from "Service/EventDispatcherService";

const Search = () => {
    const [word, setWord] = useState('')

    const handleChange = (e) => {
        setWord(e.target.value)
    }

    const handleFind = (e) => {
        e.preventDefault()
        const event = new MainEvent(EVENT_USER_INTERACT_SEARCH, {find: word})
        EventDispatcherService.fire(event)
    }

    return <form className="form-inline mr-auto" onSubmit={handleFind}>
        <div className="form-group">
            <input
                type="text"
                className="form-control"
                name="find"
                value={word}
                onChange={handleChange}
            />
        </div>
        &nbsp;
        <button type="submit" className="form-control btn btn-light">Искать</button>
    </form>
}

export default Search
