import { Button } from 'react-bootstrap'
import MainEvent from "../../Event/MainEvent";
import EventDispatcherService from "../../Service/EventDispatcherService";
import {EVENT_USER_INTERACT_SAVE} from "../../Event/EventType";

const ButtonSave = () => {
    const handleClick = () => {
        const event = new MainEvent(EVENT_USER_INTERACT_SAVE)
        EventDispatcherService.fire(event)
    }

    return <Button type={"submit"} variant="primary" onClick={handleClick}>
        Сохранить
    </Button>
}
export default ButtonSave
