import {Modal, Button} from "react-bootstrap";
import useEvent from "Hook/useEvent";
import {EVENT_USER_INTERACT_DELETE_CONFIRM} from "Event/EventType";
import {useState} from "react";

const Confirm = () => {

    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [callback, setCallback] = useState({callback: ()=>{}})

    useEvent(EVENT_USER_INTERACT_DELETE_CONFIRM, e => {
        setTitle(e.data.title)
        setText(e.data.text)
        setCallback({callback: e.data.callback})
        setShow(true)
    })

    if (!show) return null

    const onClose = () => {
        setShow(false)
    }

    const onConfirm = () => {
        setShow(false)
        callback.callback()
    }

    let textBlock = null
    if (text) {
        textBlock = <Modal.Body style={{whiteSpace: 'pre-wrap'}}>{text}</Modal.Body>
    }

    return <Modal show={show} animation={false} onHide={onClose} centered={true}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {textBlock}
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Нет</Button>
            <Button variant="primary" onClick={onConfirm}>Да</Button>
        </Modal.Footer>
    </Modal>
}

export default Confirm
