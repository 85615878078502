import AuthService from "../Service/AuthService"
import { useState } from 'react'

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors([])
        AuthService.auth({email, password})
            .then(() => {
                window.location.reload()
            })
            .catch(e => {
                setErrors([e])
            })
    }

    let errorsList = []
    if (errors) {
        for (let i in errors) {
            errorsList.push(<div className="text-danger" style={{margin: "10px 0"}} key={i}>{errors[i]}</div>)
        }
    }

    return <>
        <div className="row justify-content-center align-items-center">
            <div className="col-md-4" style={{marginTop: "100px"}}>
                <div className="card card-success">
                    <div className="card-header text-white bg-success">Вход</div>
                    <div className="card-body">
                        {errorsList}
                        <form method="post" onSubmit={handleSubmit}>
                            <div>
                                <div className="form-group">
                                    <label
                                        className="control-label required"
                                        htmlFor="form_email">E-mail</label>
                                    <input
                                        type="text"
                                        autoComplete="username"
                                        required="required"
                                        className="form-control"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label
                                        className="control-label required"
                                        htmlFor="form_password">Пароль</label>
                                    <input
                                        type="password"
                                        autoComplete="current-password"
                                        required="required"
                                        className="form-control"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-success btn">Войти
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Login
