import { Form, Modal, Button } from 'react-bootstrap'
import {useMemo, useState} from "react"
import GraphQLService from "Service/GraphQLService"
import {gql} from "@apollo/client"
import EntityService from "Service/EntityService";
import Question from "./Question";
import ButtonSave from "Component/Button/ButtonSave";
import PropTypes from "prop-types";
import {
    EVENT_USER_INTERACT_SAVE,
    EVENT_USER_INTERACT_TEST_EDIT
} from "Event/EventType";
import useEvent from "../../Hook/useEvent";

const TestEdit = ({onUpdate}) => {

    const emptyTest = useMemo(() => {
        return {
            __typename: 'Test',
            title: '',
            questions: []
        }
    }, [])

    const [test, setTest] = useState(emptyTest)
    const [display, setDisplay] = useState(false)

    useEvent(EVENT_USER_INTERACT_TEST_EDIT, e => {
        let test
        if (e.data.test) {
            test = e.data.test
        } else {
            test = emptyTest
        }
        setTest(test)
        if (test.id) {
            loadTest(test.id)
        } else {
            setDisplay(true)
        }
    }, [test, emptyTest])

    // Обработка кнопки Save
    useEvent(EVENT_USER_INTERACT_SAVE, () => {
        if (display) saveTest()
    })

    const loadTest = (testId) => {
        if (testId > 0) {
            GraphQLService.query(gql`
              query GetTestForEdit($testId: ID!) {
                  Test(id: $testId) {
                      id
                      title
                      questions {
                          id
                          text
                      }
                  }
              }
            `, {testId})
                .then(result => {
                    setTest(result.data.Test)
                    setDisplay(true)
                })
                .catch((e) => {
                    console.error(e)
                })
        }
    }

    if (!display) return null

    const saveTest = () => {
        const isValid = isValidTitle() &&
            isValidQuestions()

        if (!isValid) return

        let method = 'add'
        if (test.id) {
            method = 'update'
        }
        EntityService[method](test)
            .then(() => {
                onEditorClose()
                if (onUpdate) onUpdate()
            })
    }

    const isValidTitle = () => {
        if (!test.title) return false
        return true
    }

    const isValidQuestions = () => {
        if (0 === test.questions.length) return false
        for (let question of test.questions) {
            if ('' === question.text) return false
        }
        return true
    }

    const handleChangeTitle = (e) => {
        setTest({...test, title: e.target.value})
    }

    const onEditorClose = () => {
        setDisplay(false)
    }

    const handleAddQuestion = () => {
        const newQuestion = {
            __typename: 'Question',
            text: ""
        }
        setTest({...test, questions: [...test.questions, newQuestion]})
    }

    const onQuestionChange = (question) => {
        let i = test.questions.findIndex(item => {
            return question.id === item.id || question === item
        })
        if (i >= 0) {
            let updatedTest = {...test, questions: [...test.questions]}
            updatedTest.questions[i] = question
            setTest(updatedTest)
        }
    }

    const onQuestionDelete = (question) => {
        let i = test.questions.findIndex(item => {
            return question.id === item.id || question === item
        })
        if (i >= 0) {
            let updatedTest = {...test, questions: [...test.questions]}
            updatedTest.questions.splice(i, 1)
            setTest(updatedTest)
        }
    }

    let questions
    let count = 0
    if (test.questions) {
        questions = test.questions.map((item) => {
            count++
            return <Question
                question={item}
                onChange={onQuestionChange}
                onDelete={onQuestionDelete}
                key={count}
            />
        })
    }

    let formTitle = 'Редактирование теста'
    if (!test.id) {
        formTitle = 'Добавление теста'
    }

    // animation={false} т.к. иначе ругается реакт на "findDOMNode is deprecated in StrictMode"
    return <Modal show={display} onHide={onEditorClose} animation={false} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{formTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form noValidate onSubmit={saveTest}>
                <Form.Group>
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        type="text"
                        value={test.title}
                        placeholder="Название"
                        isValid={isValidTitle()}
                        isInvalid={!isValidTitle()}
                        onChange={handleChangeTitle}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Вопросы</Form.Label>
                    <ol>
                        {questions}
                        <div className="btn btn-success" onClick={handleAddQuestion}>Добавить вопрос</div>
                    </ol>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onEditorClose} variant="light">Закрыть</Button>
            <ButtonSave />
        </Modal.Footer>
    </Modal>
}

TestEdit.propTypes = {
    onUpdate: PropTypes.func.isRequired
}

export default TestEdit
