import AuthService from "./Service/AuthService"
import Login from "./Component/Login"
import Main from "./Component/Main"

import "./scss/custom.scss";
import './App.css'

function App() {
    let page
    if (AuthService.isAuthenticated()) {
        page = <Main />
    } else {
        page = <Login />
    }

    return (
        <div className="container App">
            {page}
        </div>
    )
}

export default App
