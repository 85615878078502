import MainEvent from "Event/MainEvent";
import {EVENT_USER_INTERACT_TEST_EDIT} from "Event/EventType";
import EventDispatcherService from "Service/EventDispatcherService";

const ButtonAddTest = () => {
    const handleTestAddOpen = (e) => {
        const event = new MainEvent(EVENT_USER_INTERACT_TEST_EDIT, {})
        EventDispatcherService.fire(event)
    }

    return <span
        className="btn btn-success navbar-right"
        onClick={handleTestAddOpen}
    >Добавить новый тест</span>
}

export default ButtonAddTest
