import PropTypes from 'prop-types'
import ReactPaginate from "react-paginate"

const Paginator = ({pageNum, pageCount, onPageClick}) =>
    <div className="navigation">
        <ReactPaginate
            previousLabel={'« Назад'}
            nextLabel={'Вперед »'}
            pageCount={pageCount}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            onPageChange={onPageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={pageNum}
        />
    </div>

Paginator.propTypes = {
    pageNum: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageClick: PropTypes.func.isRequired
}

export default Paginator
