export const formatDate = (sqlDate) => {
    if ('string' !== typeof sqlDate) return null

    let m
    // eslint-disable-next-line no-cond-assign
    if (m = sqlDate.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/)) {
        return `${m[3]}.${m[2]}.${m[1]} ${m[4]}:${m[5]}`
    }
    return sqlDate
}
