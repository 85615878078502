import EntityService from "Service/EntityService";
import PropTypes from "prop-types";
import useConfirm from "Hook/useConfirm";

const TestDelete = ({test}) => {

    const [confirmDeleteUser] = useConfirm(`Удалить тест ${test.title}?`, () => {
        deleteTest(test)
    })

    const handleDelete = () => {
        confirmDeleteUser()
    }

    const deleteTest = (test) => {
        EntityService.delete(test).then(() => {})
    }

    return <input type="submit" value="Удалить" className="btn btn-danger btn-sm" onClick={handleDelete} />
}

TestDelete.propTypes = {
    test: PropTypes.object.isRequired
}

export default TestDelete
