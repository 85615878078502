import PropTypes from 'prop-types'
import Stat from "./Stat"
import GraphChars from "./GraphChars";
import GraphSpeed from "./GraphSpeed";
import TimeInformer from "./TimeInformer"
import {useRef, useState} from "react";
import ButtonPlay from "./ButtonPlay";
import ButtonPlayColor from "./ButtonPlayColor";
import ButtonStop from "./ButtonStop";
import PlayService from "Service/PlayService";
import ButtonStopColor from "./ButtonStopColor";
import {
    EVENT_USER_INTERACT_PLAY_ANSWER,
    EVENT_USER_INTERACT_PLAY_ANSWER_COLOR,
    EVENT_USER_INTERACT_STOP_ANSWER,
    EVENT_USER_INTERACT_STOP_ANSWER_COLOR
} from "Event/EventType";
import useEvent from "Hook/useEvent";

const Answer = ({answer, num}) => {

    const [mode, setMode] = useState('stop')

    useEvent(EVENT_USER_INTERACT_PLAY_ANSWER, e => {
        if (e.data.answer.id === answer.id) {
            if (mode !== 'play') {
                setMode('play')
                PlayService.play(answerEl.current, answer)
            }
        }
    })

    useEvent(EVENT_USER_INTERACT_STOP_ANSWER, e => {
        if (e.data.answer.id === answer.id) {
            if (mode !== 'stop') {
                setMode('stop')
                PlayService.stop()
            }
        }
    })

    useEvent(EVENT_USER_INTERACT_PLAY_ANSWER_COLOR, e => {
        if (e.data.answer.id === answer.id) {
            if (mode !== 'play') {
                setMode('play')
                PlayService.playColor(answerEl.current, answer)
            }
        }
    })

    useEvent(EVENT_USER_INTERACT_STOP_ANSWER_COLOR, e => {
        if (e.data.answer.id === answer.id) {
            if (mode !== 'stop') {
                setMode('stop')
                PlayService.stopColor()
            }
        }
    })

    const answerEl = useRef(null);

    let buttonPlay
    if (mode === 'stop') {
        buttonPlay = <ButtonPlay answer={answer} />
    } else {
        buttonPlay = <ButtonStop answer={answer} />
    }

    let buttonPlayColor
    if (mode === 'stop') {
        buttonPlayColor = <ButtonPlayColor answer={answer} />
    } else {
        buttonPlayColor = <ButtonStopColor answer={answer} />
    }

    return <div className="card answer">
        <div className="card-header bg-primary text-white question-text">
            <div className="num">#{num}</div>
            {answer.question.text}
        </div>
        <div ref={answerEl} className="card-body answer-text" style={{whiteSpace: "pre-wrap"}}>
            {answer.text}
        </div>

        <div className={"toolbar"}>
            {buttonPlay}
            {buttonPlayColor}
            <TimeInformer answer={answer} />
            <Stat stat={answer.stats} text={answer.text} title="Статистика ответа" />
        </div>

        <div className="card-body">
            <GraphChars data={answer.spyLog.data} />
            <GraphSpeed data={answer.spyLog.data} />
        </div>
    </div>
}

Answer.propTypes = {
    num: PropTypes.number.isRequired,
    answer: PropTypes.shape({
        text: PropTypes.string.isRequired,
        question: PropTypes.shape({
            text: PropTypes.string.isRequired
        }),
        spyLog: PropTypes.shape({
            data: PropTypes.string
        }),
        stats: PropTypes.array
    }).isRequired
}

export default Answer
